.container {
  margin-top: 50px;
  width: 80%;
}

.Header .Upbar {
  display: flex;
  margin-bottom: 0px;
  flex-direction: row;
}

.ToggleButton {
  margin-bottom: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header img {
  height: 50px;
  align-items: center;
  margin-right: 20px;
}

.header h4 {
  font-size: 40px;
  font-weight: 800;
}

.header > div {
  display: flex;
}

.header > button {
  background-color: rgb(197, 140, 249);
  border: 1px solid rgb(120, 59, 177);
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 700;
}

.modal_header {
  position: relative;
  margin-bottom: 80px;
}

.modal_text_header {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal_close_header {
  position: absolute;
  left: 20px;
  top: 20px !important;
}

.modal_body {
  text-align: right;
}

.lableClass {
  margin-right: 10px;
}

.selectorAddNew {
  text-align: right;
}

.selectorAddNew {
  padding-right: 20px;
  margin-top: 10px;
}

.FormLabel {
  text-align: right;
}

.inputAddNew {
  text-align: right;
}

.closeButton {
  background-color: rgb(249, 140, 140);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 20px;
  border: 1px solid rgb(174, 5, 5);
}

.submit_button {
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 700;
  background-color: rgb(197, 140, 249);
  border: 1px solid rgb(120, 59, 177);
  margin-left: 10px;
}

.textAreaAddNew {
  text-align: right;
}

.Verify {
  margin-top: 20px;
}

.FlagLink {
  display: flex;
}

Link .SaedComTitle {
  text-decoration: none !important;
}



@media only screen and (max-width: 700px) {
  .Header .Upbar {
    display: flex !important;
    flex-direction: column !important;
  }

  .container {
    margin-top: 50px;
    width: 100%;
  }
  

  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  
}