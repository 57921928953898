

@media only screen and (max-width: 700px) {
    .Volunteers {
      display: flex;
      flex-direction: column-reverse !important;
      text-align: right !important;
    }
    .VolunteersDetails{
        text-align: right !important;
    }
    .VolunteersImg{
        width: 100% !important;
    }
    .GivingCasesBadge{
        margin-bottom: 50px !important;
    }
    .GivingCasesBadge{
        color: black;
    }
    body{
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: center !important;
        width: 100% !important;
    }
    
.caseContainer {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
}