.addCaseButton {
  background-color: rgb(197, 140, 249);
  border: 1px solid rgb(120, 59, 177);
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 700;
}

.modal_header {
  position: relative;
  margin-bottom: 80px;
}

.modal_text_header {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal_close_header {
  position: absolute;
  left: 20px;
  top: 20px !important;
}

.modal_body {
  text-align: right;
}

.lableClass {
  float: right !important;
  margin-bottom: 10px !important;
}

.selectorAddNew {
  text-align: right;
}

.selectorAddNew {
  padding-right: 20px;
  margin-top: 10px;
}

.FormLabel {
  text-align: right;
}

.inputAddNew {
  text-align: right;
}

.textAreaAddNew {
  text-align: right;
}

.Verify {
  margin-top: 20px;
}

.caseContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  float: right !important;
  text-align: right;
}

.caseContainer h4 {
  font-size: 27px;
  font-weight: 700;
  margin: 30px 0;
}

.submitButton {
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  background-color: rgb(197, 140, 249);
  border: 1px solid rgb(120, 59, 177);
  margin-left: 10px;
  margin: 20px 0;
}

.UploadImage {
  justify-content: center;
  direction: rtl;
}

.Recaptcha {
  margin-top: 30px;
  direction: rtl;
}


.LightDark{
  color: rgb(43, 43, 110) !important;
}

.NeedDarkLight{
  color: rgb(50, 11, 11);
}

.PaginationDark{
  color: aqua !important;
}