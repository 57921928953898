.AlertWarning {
  text-align: center !important;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.WarningP{
  text-align: center !important;
}



@media only screen and (max-width: 700px) {
  .AlertWarning {
    width: 100% !important;
    text-align: center !important;
  }
  
}