* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

.Upbar {
  display: flex;
  justify-content: space-between;
}

.HomePageLinkH4 {
  margin-top: 25px;
  font-weight: 800;
  font-size: 22px;
}
.contactLink {
  margin-top: 15px;
  margin-right: 20px;
}

.ToggleButtonDiv {
  display: flex;
  flex: 1;
}
.err {
  position: fixed;
  padding: 20px;
  font-weight: bold;
  color: white;
  background-color: rgb(234, 55, 55);
  z-index: 1000;
  top: 100px;
  right: 0;
}
.spinner.on {
  display: block;
}
.spinner {
  display: none;
  width: 25px;
  height: 25px;
  border: 3px solid;
  border-color: white white white transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation: spin 1s infinite;
  -webkit-animation: spin 1s infinite;
}
.spinner.on {
  display: block;
}
.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.ant-pagination-item a {
  margin: 10px !important;
}
.ant-pagination-item {
  background-color: #d3e2f3 !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
  font-size: 20px !important;
}

.ant-pagination {
  height: 50px;
}




@media only screen and (max-width: 700px) {



  body{
    width: 100% !important;
  }

  .Upbar {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .ToggleButtonDiv{
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
}