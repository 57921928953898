.NeededCase {
  border: 1px solid #494d4f;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: space-between;
  position: relative;
  border-radius: 5px;
}

.NeededCaseDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 20px;
  margin-left: auto !important;
  margin-top: 10px;
}

.NeededCaseImg {
  height: 300px;
  width: 100%;
}

.NeededCaseBadge {
  position: absolute;
  padding: 5px 15px !important;
  font-size: 18px !important;
  left: 35px;
  top: 20px;
}

.detailDetails {
  width: 80% !important;
  margin-left: auto;
  margin-top: 15px;
}

.NeededCaseDetails {
  position: relative;
}

.NeededCaseDetailsBadges {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.NeededCaseDetailsH1 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.NeededCaseDetailsBadge {
  padding: 5px 15px;
  border: 1px solid #434343;
}
